import { Route, Routes, Navigate } from 'react-router-dom'

import { AdminLayout } from 'components'

import Home from './Home/Home'
import Search from './Search/Search'
import Profile from './Profile/Profile'
import Administration from './Administration/Administration'
import Notifications from './Notifications/Notifications'
import Clocking from './Plannings/Clocking/Clocking'
import PlanningEngins from './Plannings/Engins/Engins'
import PlanningEmployees from './Plannings/Employee/Employee'
import Chantiers from './Chantiers/Chantiers'
import Chantier from './Chantier/Chantier'
import Employees from './Employees/Employees'
import Vehicules from './Vehicules/Vehicules'
import Engins from './Engins/Engins'
import Invoices from './Invoices/Invoices'
import Statistics from './Statistics/Statistics'
import Settings from './Settings/Settings'
import Corbeille from './Corbeille/Corbeille'
import { useAuth } from '../../contexts'

const AdminRoutes = () => {
  const { user } = useAuth()

  return <AdminLayout>
    <Routes>
      <Route path="/admin" element={<Home />} />
      <Route path="/admin/search/:search" element={<Search />} />
      <Route path="/admin/profile" element={<Profile />} />
      <Route path="/admin/administration" element={<Administration />} />
      <Route path="/admin/notification" element={<Notifications />} />
      <Route path="/admin/timesheet/clocking" element={<Clocking />} />
      <Route path="/admin/timesheet/employee" element={<PlanningEmployees />} />
      <Route path="/admin/timesheet/engins" element={<PlanningEngins />} />
      <Route path="/admin/construction-sites" element={<Chantiers />} />
      <Route path="/admin/construction-sites/:chantierId" element={<Chantier />} />
      <Route path="/admin/employee" element={<Employees />} />
      <Route path="/admin/vehicule" element={<Vehicules />} />
      <Route path="/admin/machines" element={<Engins />} />
      <Route path="/admin/invoices" element={<Invoices />} />
      {(user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0 || user.roles.filter(role => role.name === 'cdt-r').length > 0) && <Route path="/admin/statistic" element={<Statistics />} />}
      {(user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0) && <Route path="/admin/setting" element={<Settings />} />}
      {(user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0) && <Route path="/admin/recycle" element={<Corbeille />} />}
      <Route path="*" element={<Navigate to='/admin' replace />} />
    </Routes>
  </AdminLayout>
}

const SuperAdminRoutes = () => {
  return <AdminLayout>
    <Routes>
      <Route path="/admin/administration" element={<Administration />} />
      <Route path="*" element={<Navigate to='/admin/administration' replace />} />
    </Routes>
  </AdminLayout>
}

export { AdminRoutes, SuperAdminRoutes }
