import { forwardRef, useState, useEffect, useImperativeHandle } from 'react'

import { Box, HStack, Stack, useColorModeValue, Checkbox, Icon, Table, Tbody, Td, Th, Thead, Tr, Divider, Tag, Progress, Button } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import ReactPaginate from 'react-paginate'

import { Select, Input, OutputSelect } from 'components'

import './Table.css'

const MyTable = ({
  getDatas,
  columns,
  canBeSelected,
  actions,
  addOnClick,
  deleteSelectedRowsOnClick,
  defaultSortBy,
  defaultSortDir,
  canSearch,
  filterSelectInput,
  randomKey,
  extraButtons,
  errorRow = () => false
},
ref
) => {
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState({ value: 15, label: '15 résultats' })
  const [sortBy, setSortBy] = useState(defaultSortBy ?? 'id')
  const [sortDir, setSortDir] = useState(defaultSortDir ?? 'asc')
  const [searchInput, setSearchInput] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [displayedColumns, setDisplayedColumns] = useState(columns.filter(column => column.displayByDefault).map(column => ({ label: column.name.toUpperCase(), value: column.key })))
  const [datas, setDatas] = useState([])
  const [filterSelect, setFilterSelect] = useState(filterSelectInput?.length ? filterSelectInput[0] : undefined)
  const [loading, setLoading] = useState(true)

  const sortClick = (id) => {
    if (sortBy === id) {
      setSortDir(prevSortDir => prevSortDir === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(id)
      setSortDir('asc')
    }
  }

  const myGetDatas = () => {
    setLoading(true)
    getDatas({ currentPage, rowsPerPage: rowsPerPage.value, sortBy, sortDir, searchInput, filter: filterSelect?.value ?? null }, (res) => {
      setDatas(randomKey ? res.data.data.map(d => ({ ...d, randomKey: Math.random() * 10000000 })) : res.data.data)
      setTotalPage(res.data.meta.pagination.total_pages)
      setLoading(false)
    })
  }

  useEffect(() => {
    setSelectedRows([])
    setLoading(true)
    myGetDatas()
  }, [currentPage, rowsPerPage, sortBy, sortDir, searchInput, filterSelect])

  const forceReload = (newCurrentPage) => {
    if (newCurrentPage && currentPage !== newCurrentPage) setCurrentPage(newCurrentPage)
    else {
      myGetDatas()
    }
  }

  useImperativeHandle(ref, () => ({
    forceReload
  }))

  return <Box
    style={{
      border: '1px solid #d1d2e1',
      borderRadius: '8px',
      fontSize: '14px'
    }}
    bg="bg-surface"
    boxShadow={{
      base: 'none',
      md: useColorModeValue('sm', 'sm-dark')
    }}
  >
    <Box py='3' px='5'>
      <Stack
        direction={{
          base: 'column',
          md: 'row'
        }}
        justify="space-between"
      >
        <HStack spacing={'3'}>
          {canBeSelected && <Box justifyContent={'center'}>
            <Tag bg='#ff5464' size={'lg'} borderRadius='12px' color='#fff'>
              {selectedRows.length}
              {selectedRows.length ? <CloseIcon style={{ width: '10px', marginTop: '1px' }} ml='1' onClick={() => setSelectedRows([])} _hover={{ cursor: 'pointer' }} /> : ''}
            </Tag>
          </Box>}

          {canBeSelected && <Box h='50px'>
            <Divider orientation='vertical' />
          </Box>}
          <OutputSelect value={displayedColumns} setValue={setDisplayedColumns} options={columns.map(c => ({ value: c.key, label: c.name.toUpperCase() }))} multiple />
          {filterSelectInput?.length ? <Select value={filterSelect} setValue={setFilterSelect} options={filterSelectInput} /> : ''}
          {canSearch && <Input name='Rechercher...' value={searchInput} setValue={setSearchInput} />}
        </HStack>
        <HStack>
          {selectedRows.length && deleteSelectedRowsOnClick
            ? <Button data-variant='outline' onClick={() => deleteSelectedRowsOnClick(selectedRows, () => {
              setSelectedRows([])
              forceReload(1)
            })}>
            Supprimer la sélection ({selectedRows.length})
          </Button>
            : ''}
          {addOnClick && (
            <Button data-variant='solid' onClick={() => addOnClick((res) => {
              if (res?.data?.data) {
                setDatas(prevDatas => [res?.data?.data, ...prevDatas])
              }
              // forceReload()
            })}>
              Ajouter
            </Button>
          )}
          {extraButtons}
        </HStack>
      </Stack>
    </Box>
    <Box overflowX='auto'>
      <Table className='container-table'>
        <Thead className='container-thead'>
          <Tr>
            {canBeSelected && <Th>
              <Checkbox
                onChange={() => setSelectedRows(prevSelectedRows => prevSelectedRows.length === datas.length ? [] : datas.map(data => data.id))}
                isChecked={selectedRows.length === datas.length && datas.length > 0 && !loading}
                isIndeterminate={selectedRows.length !== datas.length && selectedRows.length > 0}
                borderColor={'#757575'}
              />
            </Th>}
            {columns.filter(column => displayedColumns.map(c => c.value).includes(column.key)).map(column => <Th key={`column-${column.key}`} onClick={() => column.canSort && sortClick(column.key)} _hover={column.canSort ? { cursor: 'pointer' } : {}}>
              {column.name.toUpperCase()}
              {sortBy === column.key && sortDir === 'asc' ? <ChevronUpIcon marginLeft={'5px'} fontSize='md' position='absolute' /> : sortBy === column.key ? <ChevronDownIcon marginLeft={'5px'} fontSize='md' position='absolute' /> : ''}
            </Th>)}
            {actions && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody className='container-tbody'>
          {loading
            ? <Progress className='reload_progress' size='xs' isIndeterminate backgroundImage={'linear-gradient( to right, transparent 0%, #ff5464 50%, transparent 100% '} />
            : ''
          }
          {datas.length <= 0
            ? <Tr><Td style={{ textAlign: 'center', fontSize: '14px' }} colSpan="100">{loading ? '' : 'Aucune donnée disponible'}</Td></Tr>
            : datas.map(data => <Tr key={`data-${randomKey ? data.randomKey : data.id}`} style={errorRow(data) ? { backgroundColor: '#ff5464' } : {}}>
              {canBeSelected && <Td>
                <Checkbox
                  borderColor={'#757575'}
                  isChecked={selectedRows.includes(data.id)}
                  onChange={() => setSelectedRows(prevSelectedRows => selectedRows.includes(data.id) ? prevSelectedRows.filter(prevSelectedRow => prevSelectedRow !== data.id) : [...prevSelectedRows, data.id])}
                />
              </Td>}
              {columns.filter(column => displayedColumns.map(c => c.value).includes(column.key)).map(column => <Td key={`data-${randomKey ? data.randomKey : data.id}-column-${column.key}`}>
                {column.display(data[column.key], data)}
              </Td>)}
              {actions && <Td>
                {actions(data, () => forceReload())}
              </Td>}
            </Tr>)
          }
          </Tbody>
      </Table>
      <HStack spacing="3" justify="space-between" className='footer-pagination' px='4' py='3'>
        <ReactPaginate
          previousLabel={<Icon as={MdOutlineKeyboardArrowLeft} fontSize="1.50rem" />}
          nextLabel={<Icon as={MdOutlineKeyboardArrowRight} fontSize="1.50rem" />}
          breakLabel="..."
          onPageChange={event => {
            setCurrentPage(event.selected + 1)
          }}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
        <Select name='Nombre par page' value={rowsPerPage} setValue={setRowsPerPage} options={[
          { value: 15, label: '15 résultats' },
          { value: 30, label: '30 résultats' },
          { value: 100, label: '100 résultats' }
        ]} width='210px' />
      </HStack>
    </Box>
  </Box>
}

export default forwardRef(MyTable)
