import { useLocation } from 'react-router-dom'

import { Flex, Stack } from '@chakra-ui/react'

import { Icons } from 'components'

import Logo from './Logo'
import NavButton from './NavButton'

import { useAuth } from 'contexts'

const adminMenuItems = [
  {
    name: "Feuilles d'heures",
    icon: Icons.Clocking,
    link: '/admin/timesheet/clocking'
  },
  {
    name: 'Planning ouvrier',
    icon: Icons.PlanningEmployee,
    link: '/admin/timesheet/employee'
  },
  {
    name: 'Planning engins',
    icon: Icons.PlanningMachine,
    link: '/admin/timesheet/engins'
  },
  {
    name: 'Chantiers',
    icon: Icons.Chantier,
    link: '/admin/construction-sites'
  },
  {
    name: 'Employés',
    icon: Icons.Employee,
    link: '/admin/employee'
  },
  {
    name: 'Véhicules',
    icon: Icons.Vehicule,
    link: '/admin/vehicule'
  },
  {
    name: 'Engins',
    icon: Icons.Engins,
    link: '/admin/machines'
  },
  {
    name: 'Factures',
    icon: Icons.Facture,
    link: '/admin/invoices'
  },
  {
    name: 'Chiffres',
    icon: Icons.Chiffre,
    link: '/admin/statistic',
    permission: (user) => user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0 || user.roles.filter(role => role.name === 'cdt-r').length > 0
  },
  {
    name: 'Paramètres',
    icon: Icons.Setting,
    link: '/admin/setting',
    permission: (user) => user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0
  },
  {
    name: 'Corbeille',
    icon: Icons.Corbeille,
    link: '/admin/recycle',
    permission: (user) => user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0
  }
]

const Sidebar = ({ onItemClick = () => {} }) => {
  const { role: currentUserRole, user } = useAuth()
  const location = useLocation()

  return (
		<Flex
			as="section"
			h="100%"
      position="fixed"
			bg="brand.sidebar"
			zIndex={'12'}
		>
			<Flex flex="1" overflowY="auto" w={'15rem'}>
				<Stack justify="space-between" spacing="1" width={'full'}>
					<Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren >
						<Logo />
						<Stack spacing="1" align="flex-start" mb={'4rem'}>
							{currentUserRole === 'admin' &&
							  adminMenuItems.map(item => (!item.permission || item.permission(user)) && <NavButton
									to={item.link}
									label={item.name}
									icon={item.icon}
                  onClick={onItemClick}
                  key={`item-${item.name}`}
                  className={location.pathname.includes(item.link) ? 'navbar-menu active' : 'navbar-menu'}
								/>)}
						</Stack>
					</Stack>
				</Stack>
			</Flex>
		</Flex>
  )
}

export default Sidebar
